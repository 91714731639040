import {
  CustomerType,
  OrganizationFragment,
  OrganizationType,
  useCreateDwollaCustomerMutation,
  useCreateOrganizationMutation,
  useGetOrCreateCustomerMutation,
  useUpdateOrganizationMutation,
  useUserQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { useEarnnestAPI } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAPI"
import { PanelFooter } from "@earnnest-e2-frontend/platform-ui/src/mantine/Panel"
import {
  Anchor,
  Button,
  Box,
  Group,
  Input,
  MultiSelect,
  Select,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core"
import { useForm, yupResolver } from "@mantine/form"
import { notifications } from "@mantine/notifications"
import { useMemo, useState } from "react"
import { RiQuestionFill } from "react-icons/ri"
import MaskedInput from "react-text-mask"
import * as yup from "yup"

export default function OrganizationProfileForm({
  organization,
  onSubmitSuccess,
  allowNotificationEdits = false,
  viewerRole = "admin",
}: {
  organization?: OrganizationFragment
  onSubmitSuccess?: (organization: OrganizationFragment) => void
  allowNotificationEdits?: boolean
  viewerRole?: "superuser" | "admin"
}) {
  const { publicAPI } = useEarnnestAPI()

  const userQuery = useUserQuery()
  const user = userQuery.data?.user

  const initialType = organization?.organizationType?.toUpperCase()

  const slugValidation = !!organization
    ? yup.string().required("Required")
    : yup
        .string()
        .required("Required")
        .matches(
          /^[\w\d_-]+$/,
          "Use only letters, numbers, underscores, or hyphens",
        )

  const validationSchema = useMemo(() => {
    return viewerRole === "superuser"
      ? yupResolver(
          yup.object({
            name: yup.string().required("Required"),
            type: yup.string(),
            email: yup.string(),
            phone: yup.string(),
            slug: slugValidation,
            address: yup.string(),
            url: yup.string(),
            holdsEscrow: yup.boolean(),
            emails: yup.array().of(yup.string().email("Emails must be valid")),
            newEmail: yup.string().email("Custom email is not valid"),
          }),
        )
      : yupResolver(
          yup.object({
            name: yup.string().required("Required"),
            type: yup.string().required("Required"),
            email: yup.string().required("Required"),
            phone: yup.string().required("Required"),
            // ein: yup.string().required("Required"),
            slug: slugValidation,
            address: yup.string().required("Required"),
            url: yup.string().required("Required"),
            holdsEscrow: yup.boolean(),
            emails: yup.array().of(yup.string().email("Emails must be valid")),
            newEmail: yup.string().email("Custom email is not valid"),
          }),
        )
  }, [viewerRole, slugValidation])

  const form = useForm({
    initialValues: {
      name: organization?.name || "",
      type: initialType || "",
      email: organization?.email || "",
      phone: organization?.phone || "",
      ein: organization?.ein || "",
      slug: organization?.slug || "",
      address: organization?.address || "",
      url: organization?.url || "",
      holdsEscrow: organization?.holdsEscrow ?? true,
      emails: organization?.emails || [],
      newEmail: "",
    },
    validate: validationSchema,
    validateInputOnBlur: true,
  })

  const [submitting, setSubmitting] = useState(false)

  const [createDwollaCustomer] = useCreateDwollaCustomerMutation()
  const [getOrCreateCustomer] = useGetOrCreateCustomerMutation()
  const [createOrganization] = useCreateOrganizationMutation()
  const [updateOrganization] = useUpdateOrganizationMutation()

  const phoneMaskWithoutCountryCode = [
    /[1-9]/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ]

  return (
    <form
      onSubmit={form.onSubmit(async (values) => {
        setSubmitting(true)
        try {
          if (!organization) {
            const result = await publicAPI.get("/organizations/" + values.slug)
            await result.json()
            // slug collision
            form.setFieldError("slug", "Already claimed")
            setSubmitting(false)
            return
          }
        } catch {
          // error, no slug collision, continue with creation
        }
        try {
          if (!!organization) {
            const updateOrganizationResult = await updateOrganization({
              variables: {
                orgId: organization.id,
                name: values.name,
                type: values.type,
                // have to check that value is in the enum for this one...
                organizationType: Object.values(OrganizationType).includes(
                  values.type,
                )
                  ? values.type
                  : null,
                email: values.email,
                phone: values.phone,
                address: values.address,
                url: values.url,
                emails: values.emails,
                ...(viewerRole === "superuser" ? { slug: values.slug } : {}),
              },
            })
            onSubmitSuccess?.(updateOrganizationResult.data.updateOrganization)
          } else {
            let customerId
            if (values.holdsEscrow) {
              // Create customer in dwolla
              const dwollaCustomerResult = await createDwollaCustomer({
                variables: {
                  firstName: user.firstName,
                  lastName: user.lastName,
                  email: values.email,
                  phone: values.phone,
                  businessName: values.name,
                },
              })
              const dwollaCustomerId =
                dwollaCustomerResult?.data?.createDwollaCustomer?.id

              // Link dwolla customer to new earnnest customer
              const createCustomerResult = await getOrCreateCustomer({
                variables: {
                  customerRef: dwollaCustomerId,
                  type: "BUSINESS" as CustomerType,
                },
              })
              customerId = createCustomerResult?.data?.getOrCreateCustomer?.id
            }

            // Create org and link customer if applicable
            const createOrganizationResult = await createOrganization({
              variables: {
                name: values.name,
                type: values.type,
                // have to check that value is in the enum for this one...
                organizationType: Object.values(OrganizationType).includes(
                  values.type,
                )
                  ? values.type
                  : null,
                email: values.email,
                phone: values.phone,
                slug: values.slug,
                address: values.address,
                url: values.url,
                holdsEscrow: values.holdsEscrow,
                makeCurrentUserAdmin: true,
                customerId,
              },
            })

            onSubmitSuccess?.(createOrganizationResult.data.createOrganization)
          }
        } catch (error) {
          // would rather have a code of some sort but this is it for now
          if (error.message === "Please contact support to create a business") {
            notifications.show({
              color: "green",
              title: "Your business info has been received!",
              message:
                "Hang tight while we verify your business. Please contact sales@earnnest.com if you have any questions.",
            })
          } else if (
            error.message ===
            "A customer with the specified email already exists."
          ) {
            notifications.show({
              color: "red",
              title: "Error",
              message: "This email has already been used to create a business.",
            })
          } else if (error.message === "slug: has already been taken") {
            notifications.show({
              color: "red",
              title: "Error",
              message:
                "Please select a different friendly URL or contact support at 1-864-568-4659 for assistance.",
              autoClose: false,
            })
          } else {
            notifications.show({
              color: "red",
              title: "Error",
              message: "Something went wrong",
            })
          }
        } finally {
          setSubmitting(false)
        }
      })}>
      <Stack spacing="sm">
        <Group grow align="start">
          <TextInput
            label="Business Name"
            placeholder="Business Name"
            {...form.getInputProps("name")}
          />
          <Select
            label="Business Type"
            placeholder="Select one..."
            data={[
              { value: OrganizationType.Association, label: "Association" },
              { value: OrganizationType.Attorney, label: "Attorney" },
              { value: OrganizationType.Brokerage, label: "Brokerage" },
              { value: OrganizationType.Title, label: "Title" },
            ]}
            {...form.getInputProps("type")}
          />
        </Group>
        <Group grow align="start">
          <TextInput
            label="Business Email"
            placeholder="Business Email"
            type="email"
            {...form.getInputProps("email")}
          />
          <Input.Wrapper
            label="Business Phone"
            {...form.getInputProps("phone")}>
            <Input
              placeholder="Business Phone"
              component={MaskedInput}
              mask={(rawValue) => {
                if (rawValue.length > 0 && rawValue[0] === "1") {
                  return ["1", "-", ...phoneMaskWithoutCountryCode]
                }
                return phoneMaskWithoutCountryCode
              }}
              inputMode="numeric"
              guide={false}
              {...form.getInputProps("phone")}
            />
          </Input.Wrapper>
        </Group>
        <Group grow align="start">
          <Input.Wrapper label="Friendly URL" {...form.getInputProps("slug")}>
            <Input
              placeholder="your_business_name"
              readOnly={viewerRole !== "superuser" && !!organization?.slug}
              disabled={viewerRole !== "superuser" && !!organization?.slug}
              rightSection={
                <Tooltip
                  label={
                    <Text size="sm" px={6} py={3} align="center">
                      Once set up, your organization dashboard will be at{" "}
                      <Text size="sm" color="green">
                        {`${process.env.REACT_APP_DASHBOARD_DOMAIN}/${
                          form.values.slug || "your_business_name"
                        }`}
                      </Text>
                    </Text>
                  }>
                  <Box>
                    <RiQuestionFill />
                  </Box>
                </Tooltip>
              }
              {...form.getInputProps("slug")}
            />
          </Input.Wrapper>
          <TextInput
            label="Business Website"
            placeholder="Business Website"
            {...form.getInputProps("url")}
          />
        </Group>
        <TextInput
          label="Business Address"
          placeholder="Business Address"
          {...form.getInputProps("address")}
        />
        {organization && allowNotificationEdits ? (
          <MultiSelect
            label="Additional Emails"
            placeholder="Add additional notification recipients"
            data={form.values.emails}
            error={form.errors.emails || form.errors.newEmail}
            value={form.values.emails}
            onChange={(query) => {
              form.setFieldValue("emails", query)
            }}
            searchable
            searchValue={form.values.newEmail}
            onSearchChange={(query) => {
              form.setFieldValue("newEmail", query.trim())
              form.validateField("newEmail")
            }}
            creatable
            getCreateLabel={(query) => `Click to add new email: ${query}`}
            onCreate={(query) => {
              if (!form.errors.newEmail) {
                form.setFieldValue("emails", [...form.values.emails, query])
                return query
              }
              return
            }}
          />
        ) : null}
      </Stack>
      <PanelFooter>
        <Button type="submit" size="lg" loading={submitting}>
          Continue
        </Button>
        {!organization && form.values.holdsEscrow ? (
          <Text mt="xs" size="xs" align="center">
            By clicking "Continue", you are creating a Dwolla account and agree
            to <br /> Dwolla's{" "}
            <Anchor
              size="xs"
              href="https://www.dwolla.com/legal/tos/"
              target="_blank">
              Terms and Conditions
            </Anchor>
            {" and "}
            <Anchor
              size="xs"
              href="https://www.dwolla.com/legal/privacy/"
              target="_blank">
              Privacy Policy
            </Anchor>
            .
          </Text>
        ) : null}
      </PanelFooter>
    </form>
  )
}
