import {
  useRequestUserRolesMutation,
  UserPermission,
  useUserQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { PanelFooter } from "@earnnest-e2-frontend/platform-ui/src/mantine/Panel"
import {
  Box,
  Button,
  Flex,
  LoadingOverlay,
  Space,
  Stack,
  Text,
  Title,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { notifications } from "@mantine/notifications"
import { useState } from "react"
import { RiCheckboxCircleFill } from "react-icons/ri"
import { useHistory } from "react-router-dom"

export default function BusinessFrontMatterScreen() {
  const history = useHistory()
  const [requestUserRoles] = useRequestUserRolesMutation({
    variables: {
      roles: [UserPermission.RequestEscrow],
    },
  })
  const userQuery = useUserQuery({
    pollInterval: 5000,
    onCompleted: (data) => {
      const user = data?.user
      form?.setFieldValue("allowOrgRegistration", user?.allowOrgRegistration)
    },
  })
  const user = userQuery?.data?.user
  const [submitting, setSubmitting] = useState(false)

  const form = useForm<{ allowOrgRegistration: boolean }>({
    initialValues: {
      allowOrgRegistration: user?.allowOrgRegistration,
    },
    validate: {
      allowOrgRegistration: (aor) => (aor ? null : "error"),
    },
  })

  const handleSubmit = async () => {
    try {
      setSubmitting(true)
      notifications.clean()
      await requestUserRoles()
      history.push("/business/profile")
    } catch (error) {
      notifications.show({
        color: "red",
        title: "Error",
        message: "Something went wrong",
      })
    } finally {
      setSubmitting(false)
    }
  }

  const handleError = (errors: typeof form.errors) => {
    if (errors.allowOrgRegistration) {
      notifications.show({
        autoClose: false,
        color: "gray.0",
        title: "Complete your onboarding",
        message: (
          <Text mt="xs">
            <Text span>Contact our Strategic Partnerships Team at </Text>
            <Text component="a" href="mailto:sales@earnnest.com" color="green">
              sales@earnnest.com
            </Text>
            <Text span> or call </Text>
            <Text component="a" href="tel:+1-864-973-7400" color="green">
              1-864-568-4659
            </Text>
            <Text span> to complete your onboarding.</Text>
          </Text>
        ),
        styles: (theme, params) => ({
          root: {
            border: "none",
            backgroundColor: theme.fn.themeColor(params.color, 5),
            "&::before": {
              display: "none",
            },
          },
          title: {
            color: theme.fn.themeColor("dark"),
            fontSize: theme.fontSizes.md,
            fontWeight: 600,
          },
          description: {
            color: theme.fn.themeColor("dark"),
          },
          closeButton: {
            border: "none",
            color: theme.fn.themeColor("dark"),
            "&:hover": {
              backgroundColor: theme.fn.themeColor("gray.2"),
            },
          },
        }),
      })
    }
  }

  if (!user) {
    return <LoadingOverlay visible />
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit, handleError)}>
      <Space h={38} />
      <Title size="h2" order={2}>
        Enroll your business
      </Title>
      <Space h={8} />
      <Text size="lg" color="gray.8">
        Enrollment takes just a few minutes. Here are a few things to have handy
        while enrolling your business:
      </Text>
      <Space h={40} />
      <Stack spacing={12}>
        <Flex direction="row" justify="start" align="start" gap={14}>
          <Box h={24} w={24} pt={1}>
            <RiCheckboxCircleFill size={24} />
          </Box>
          <Text size="lg">Business phone and email</Text>
        </Flex>
        <Flex direction="row" justify="start" align="start" gap={14}>
          <Box h={24} w={24} pt={1}>
            <RiCheckboxCircleFill size={24} />
          </Box>
          <Text size="lg">Business URL</Text>
        </Flex>
        <Flex direction="row" justify="start" align="start" gap={14}>
          <Box h={24} w={24} pt={1}>
            <RiCheckboxCircleFill size={24} />
          </Box>
          <Text size="lg">
            Business bank account information (if you want to receive earnest
            money deposits)
          </Text>
        </Flex>
      </Stack>
      <Space h={40} />
      <Box>
        <Title order={4}>Questions?</Title>
        <Space h={8} />
        <Text span size="lg">
          Call us (Mon-Fri 8am-8pm EST):{" "}
        </Text>
        <Text size="lg" component="a" href="tel:+1-864-568-4659" color="green">
          1-864-568-4659
        </Text>
      </Box>
      <PanelFooter>
        <Button type="submit" size="lg" loading={submitting}>
          Continue
        </Button>
        {form.errors.allowOrgRegistration ? (
          <Text mt="xs" px="xl" align="center">
            <Text span size="xs">
              Contact our Strategic Partnerships Team at{" "}
            </Text>
            <Text
              size="xs"
              component="a"
              href="mailto:sales@earnnest.com"
              color="green">
              sales@earnnest.com
            </Text>
            <Text span size="xs">
              {" "}
              or call{" "}
            </Text>
            <Text
              size="xs"
              component="a"
              href="tel:+1-864-973-7400"
              color="green">
              1-864-568-4659
            </Text>
            <Text span size="xs">
              {" "}
              to complete your onboarding.
            </Text>
          </Text>
        ) : null}
      </PanelFooter>
    </form>
  )
}
