import { useOrganizationQuery } from "@earnnest-e2-frontend/platform-api/src/graphql"
import {
  BankIcon,
  IdentityIcon,
} from "@earnnest-e2-frontend/platform-ui/src/Icons"
import { PanelFooter } from "@earnnest-e2-frontend/platform-ui/src/mantine/Panel"
import {
  Button,
  Center,
  Flex,
  Space,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core"
import { useParams } from "react-router-dom"

export default function BusinessWelcomeLandingScreen() {
  const { orgSlug } = useParams()

  const organizationQueryResult = useOrganizationQuery({
    variables: {
      slug: orgSlug,
    },
  })

  const organization = organizationQueryResult?.data?.organization
  const onboardingChecklistUrl = `${process.env.REACT_APP_DASHBOARD_DOMAIN}/${organization?.slug}/home`

  if (!organization) {
    return null
  }

  return (
    <Stack px={54}>
      <Space h="xl" />
      <Center>
        <ThemeIcon
          color="green"
          variant="filled"
          radius={100}
          size={100}
          p="xs">
          <svg
            width="49"
            height="48"
            viewBox="0 0 49 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.4375 26.3828L19.6875 38.8047L42.875 6.50781"
              stroke="white"
              stroke-width="11.5938"
              stroke-linecap="round"
            />
          </svg>
        </ThemeIcon>
      </Center>
      <Title align="center" size="h2" order={2}>
        Welcome, {organization.name}
      </Title>
      <Text align="center">
        You're one step closer to embracing the future of real estate
        transactions! Here's what you need to do next to move forward:
      </Text>
      <Space h="sm" />
      <Stack align="center">
        <Flex direction="row" align="start" justify="space-between">
          <ThemeIcon
            color="dark.0"
            variant="filled"
            radius={100}
            size={55}
            p="xs">
            <BankIcon />
          </ThemeIcon>
          <Space w={18} />
          <Stack spacing={9}>
            <Title size="h4" order={4}>
              Add Escrow Accounts
            </Title>
            <Text>
              Escrow accounts are designed for earnest money payments and will
              be searchable within our network by the selected states.
            </Text>
          </Stack>
        </Flex>
        <Flex direction="row" align="start" justify="space-between">
          <ThemeIcon
            color="dark.0"
            variant="filled"
            radius={100}
            size={55}
            p="xs">
            <BankIcon />
          </ThemeIcon>
          <Space w={18} />
          <Stack spacing={9}>
            <Title size="h4" order={4}>
              Add Funding Sources
            </Title>
            <Text>
              Funding sources are designed for non-escrow related payment types
              such as agent fees, rental payments, builder deposits, and more.
            </Text>
          </Stack>
        </Flex>
        <Flex direction="row" align="start" justify="space-between">
          <ThemeIcon
            color="dark.0"
            variant="filled"
            radius={100}
            size={55}
            p="xs">
            <IdentityIcon />
          </ThemeIcon>
          <Space w={18} />
          <Stack spacing={9}>
            <Title size="h4" order={4}>
              Manage Settings
            </Title>
            <Text>
              While not required, settings offer the ability to upload
              organization branding assets, set up email notifications, and
              designate account admins.
            </Text>
          </Stack>
        </Flex>
      </Stack>
      <PanelFooter>
        <Button component="a" size="lg" href={onboardingChecklistUrl}>
          Continue
        </Button>
      </PanelFooter>
    </Stack>
  )
}
